.loader {
  border: 16px solid #f3f3f3; /* Light grey */
  border-top: 16px solid #3498db; /* Blue */
  border-radius: 50%;
  margin: auto;
  animation: spin 2s linear infinite;

  &-sm {
    width: 60px;
    height: 60px;
  }

  &-md {
    width: 120px;
    height: 120px;
  }

  &-lg {
    width: 180px;
    height: 180px;
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
