.matches {
    & .card {
        background: #ffffff;
    }

    & .our-team {
        background: #2C3B49;
        border: none;

        & h2 {
            color: #fff;
        }
    }

    & .hidden {
        display: none;
    }
}

@keyframes blink {
    0% {
        box-shadow: 0 0 7.5px #1a287f;
    }
    50% {
        box-shadow: none;
    }
    100% {
        box-shadow: 0 0 7.5px #1a287f;
    }
}
