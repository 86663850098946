.DriverDashboardPage {
    & .upcoming-match {
        & h2 {
            color: #1a287f;
            font-size: 2rem;
            text-align: center;
            margin-bottom: 1.5rem;
        }
    }
    & .form {
        width: 100%;

        & h2 {
            color: #1a287f;
            font-size: 2rem;
            margin-bottom: 1.5rem;
        }

        & h3 {
            color: #2cace4;
            font-size: 1.75rem;
            margin: 1.5rem 0;
        }

        & p {
            font-size: 1.5rem;
            font-weight: 600;
            margin-bottom: 1rem;
        }

        & .row {
            display: flex;
            flex-direction: row;
            align-items: flex-start;
            justify-content: center;

            &:not(:last-child) {
                margin-bottom: 1.5rem;
            }

            & .column-1 {
                width: 50%;
                margin-right: 2.5rem;
            }

            & .column-2 {
                width: 50%;
            }
        }

        & .horizontal-line {
            width: 100%;
            height: 2px;
            margin-top: 1.5rem;
            margin-bottom: 1.5rem;
            border-top: 1px solid #caced6;
        }
        & .record-row {
            display: flex;
            flex-direction: row;
            align-items: flex-start;
            justify-content: center;
            & .column-1 {
                width: 33.33%;
                margin-right: 0rem;
            }
            & .column-2 {
                width: 33.33%;
            }
            & .column-3 {
                width: 33.33%;
            }
        }
    }
}

table {
    background: #ffffff;
    border-radius: 0.25rem;
    width: 100%;
    border-spacing: 0;
    margin-bottom: 2rem;

    & thead {
        width: 100%;

        & tr {
            width: 100%;

            & th {
                background-color: #f8f9fa;
                border-bottom: 1px solid #dee2e6;
                padding: 1rem;
                width: 25%;
                text-align: center;
                font-size: 1.5rem;
                font-weight: 600;
            }
        }
    }

    & tbody {
        width: 100%;

        & .blue-row {
            background: #eef;
        }

        & .red-row {
            background: #fee;
        }

        & tr {
            width: 100%;

            & td {
                padding: 1rem;
                border-bottom: 1px solid #dee2e6;
                text-align: center;
                font-size: 1.25rem;
            }

            &:last-child {
                & td {
                    border-bottom: none;
                }
            }
        }
    }
}

.predicted-scores {
    margin-bottom: 2.5rem;

    & .teams {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;

        & .team {
            width: 50%;
            
            & h3 {
                margin-bottom: 1rem;
                font-size: 1.75rem;
                text-align: center;
            }

            & p {
                font-size: 1.5rem;
                text-align: center;
            }

            &:not(:last-child) {
                margin-right: 2.5rem;
            }

            &:first-child {
                
                & h3 {
                    color: #2cace4;
                }
            }

            &:last-child {

                & h3 {
                    color: #FF9292;
                }
                
            }
        }
    }
}