.InputComponent {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    width: 100%;

    &:not(:last-child) {
        margin-bottom: 1.5rem;
    }

    & input {
        width: 100%;
    }

    & .input-buttons {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;

        & input {
            width: 45%;
            margin-right: 1rem;
        }

        & .buttons {
            margin: 0;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;

            & button {
                font-size: 2rem;
                padding: 0.5rem 1.5rem;

                &:first-child {
                    margin-right: 0.5rem;
                }
            }
        }
    }
}
