.App {
  background-color: #fbfcff;
  min-height: 100vh;
  padding: 2rem;
}

/* 
a {
  cursor: pointer;
  text-decoration: none;
  color: #020224;
}

h1 {
  text-align: center;
  margin-top: 0px;
  padding-top: 40px;
  font-size: 40px;
}

* {
  color: #FFFFFF;
}

p {
  margin-left: 15px;
}

button {
  font-family: 'Poppins';
  font-size: 15px;
  font-weight: bold;
  position: absolute;
  bottom: 40px;
  left: 50%;
  transform: translate(-50%, 0);
  padding: 15px 100px;
  border-radius: 15px;
  outline: none;
  border: none;
  background-color: #F7B32B;
  cursor: pointer;
} */
