.EditMatchPage {

    & h2 {
        color: #1a287f;
        font-size: 2rem;
        margin-bottom: 1.5rem;
    }

    h3 {
        color: #2CACE4;
        font-size: 1.75rem;
        margin-bottom: 1.5rem;
    }

    & .form {
        width: 100%;

        & .row {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;

            & .column-1 {
                width: 50%;
                margin-right: 2.5rem;
            }

            & .column-2 {
                width: 50%;
            }
        }

        & .horizontal-line {
            width: 100%;
            height: 2px;
            margin-top: 1.5rem;
            margin-bottom: 1.5rem;
            border-top: 1px solid #CACED6;
        }

        & .input-row {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            width: 100%;

            & .text-input {
                width: 100%;
                margin-right: 2.5rem;

                & input {
                    width: 100%;
                }
            }

            &:not(:last-child) {
                margin-bottom: 1.5rem;
            }
        }

        & .edit-buttons {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            margin-top: 2.5rem;
        }
        
        .notes {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: center;
            
            & textarea {
                width: 100%;
            }
        }
    }
}
