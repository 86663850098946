.CompPage {
    
    & p {
        font-size: 1.5rem;
    }

    & h3 {
        font-size: 2rem;
        font-weight: 500;
        margin: 1rem 0;
        text-align: center;
        color: #202020;
    }
}