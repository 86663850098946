.HomePage {
    & h1 {
        font-size: 2.5rem;
        margin-bottom: 1.5rem;
    }

    & p {
        font-size: 1.5rem;
    }

    & .buttons {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        margin-top: 2rem;
    }
}