.MatchPage {

    & h2 {
        text-align: center;
        margin-bottom: 1rem;
    }

    & .teams {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        & .blue-alliance, .red-alliance {
            width: 100%;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;

            & a {
                width: 30%;
            } 

            & .card {
                margin: 0;
                text-align: center;
                border: none;
            }

        }

        & .blue-alliance {
            margin-bottom: 1rem;

            & a {

                & .card {
                    background: #ddf;

                    &:hover {
                        transition: all ease-in-out 0.5s;
                        background: rgba(#9191ff, 0.75);
                    }
                }
            }
        }
        
        & .red-alliance {
            
            & a {

                & .card {
                    background: #fdd;

                    &:hover {
                        transition: all ease-in-out 0.5s;
                        background: rgba(#ff9191, 0.75);
                    }
                }
            }
        }
    }
}