@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

* {
    color: #020224;
    box-sizing: border-box;
}

* {
    box-sizing: border-box !important;
    font-family: "Poppins";
}

html {
    font-size: 10px;

    & body {
        padding: 0;
        margin: 0;
        background-color: #f6f6f2;
    }
}

p,
h1,
h2,
h3,
h4,
h5,
h6 {
    margin: 0;
}

a {
    text-decoration: none;
}

label {
    color: #2c3b49;
    font-size: 1.5rem;
    font-weight: 600;
    margin-bottom: 1rem;
}

input,
textarea {
    border: 1px solid #caced6;
    border-radius: 0.5rem;
    padding: 1rem;

    &:focus {
        border: 1px solid #2cace4;
        color: #2cace4;
        outline: none;
    }
}

.input-box {
    background: #ffffff;
    border: 1px solid #caced6;
    border-radius: 0.5rem;
    padding: 1rem;
    font-size: 1.25rem;

    &:focus {
        border: 1px solid #2cace4;
        color: #2cace4;
        outline: none;
    }
}


.card {
    background: #ffffff;
    border: 1px solid #CACED6;
    // box-shadow: 5px 5px 20px 2.5px rgba(0, 0, 0, 0.1);
    padding: 1.5rem;
    margin: 1.5rem 0;
    border-radius: 1rem;

    & p {
        color: #202020;
        font-weight: 500;
        font-size: 1.5rem;
    }
    
    &:hover {
        transition: all ease-in-out 0.5s;
        background: rgba(#2cace4, 0.25);
    }
}

.button {
    padding: 1rem 2rem;
    font-size: 1.5rem;
    cursor: pointer;
    border: none;

    &:focus {
        outline: none;
    }
}

.purple-button {
    background: #1a287f;
    color: #ffffff;
}

.green-button {
    background: #3dbf79;
    color: #ffffff;
}

.red-button {
    background: #de4f46;
    color: #ffffff;
}

.round-button {
    border-radius: 5rem;
}

.square-button {
    border-radius: 1rem;
}

.react-tabs__tab-list {
    margin: 1.5rem 0 !important;
    border-bottom: 1px solid #1a287f !important;
}

.react-tabs__tab {
    font-size: 1.5rem;
    text-align: center;
    padding: 1rem 1.5rem !important;
}

.react-tabs__tab--selected {
    background: #1a287f !important;
    color: #ffffff !important;
    border: none !important;
}

.header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 1.5rem;

    & h1 {
        color: #2c3b49;
        font-size: 2rem;
        // margin-bottom: 2.5rem;
        text-align: center;
        padding: 0;
    }

    & .arrow {
        font-size: 2.5rem;
        cursor: pointer;
    }
}